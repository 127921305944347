import React from 'react'
import { bool } from 'prop-types'
import { styled, Stack, Typography, Box } from '@mui/material'
import { isAndroid, isIOS } from 'react-device-detect'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import AppImage from '@assets/images/order-success-mobile-app.png'
import CondensedContact from '@components/shared/condensed-contact-links-v2'
import AppStore from '@components/shared/svgs/AppStore'
import GooglePlay from '@components/shared/svgs/GooglePlay'

const AppInfoContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  minWidth: '350px',
  maxWidth: '420px',
  img: { width: '137px', height: '143px' },
  [theme.breakpoints.down('lg')]: {
    img: {
      width: 'auto',
      maxWidth: '100px',
      objectFit: 'cover',
      objectPosition: 'right',
    },
  },
}))

const DownloadTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '25px',
  fontWeight: 500,
  textAlign: 'center',
}))

const DownloadWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '7px',
  height: '46px',
  width: '153px',
  svg: { height: '100%', path: { fill: '#F2F2F2' } },
}))

const StyledCondensedContact = styled(CondensedContact)(() => ({
  p: { textAlign: 'center', paddingTop: '48px' },
}))

const AppDownload = ({ showContactUs = false }) => (
  <AppInfoContainer pb={4} pt={6} className="no_print">
    <img src={AppImage} alt="mobile-app" />
    <Stack spacing={2} pl={1} alignItems="center">
      <DownloadTitle>Download the app to track your delivery</DownloadTitle>
      {!isAndroid && (
        <DownloadWrapper>
          <StrapiLink
            className="footer__store-downloads__store-icon"
            data={{
              ExternalUrl: 'https://apps.apple.com/us/app/room-to-go/id898852501',
              displayText: 'Get the Rooms To Go App',
              id: 'rtg-app-ios',
            }}
            trackingData={{
              category: 'footer',
              action: 'click',
              label: 'RTG iOS app download',
            }}
          >
            <AppStore />
          </StrapiLink>
        </DownloadWrapper>
      )}
      {!isIOS && (
        <DownloadWrapper>
          <StrapiLink
            className="footer__store-downloads__store-icon"
            data={{
              ExternalUrl: 'https://play.google.com/store/apps/details?id=com.roomstogo.dealstogo',
              displayText: 'Get the Rooms To Go App',
              id: 'rtg-app-android',
            }}
            trackingData={{
              category: 'footer',
              action: 'click',
              label: 'RTG Android app download',
            }}
          >
            <GooglePlay />
          </StrapiLink>
        </DownloadWrapper>
      )}
      {showContactUs && <StyledCondensedContact />}
    </Stack>
  </AppInfoContainer>
)

AppDownload.propTypes = {
  showContactUs: bool,
}

export default AppDownload
