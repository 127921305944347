import React from 'react'
import { any, array, bool, string } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { titleCase } from '@helpers/string-helper'

const useStyles = makeStyles({
  root: {
    '&&&': {
      fontSize: '12px',
      color: '#333333',
      marginTop: '24px',
      '& span': {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      '& i': {
        fontSize: '12px',
        fontStyle: 'italic',
        lineHeight: '24px',
      },
    },
  },
  borders: {
    borderTop: '1px solid #e7e7e7',
    borderBottom: '1px solid #e7e7e7',
  },
  text: {
    '&&&&': {
      padding: ({ fullWidth }) => (fullWidth ? '15px 0 5px 0;' : '10px'),
    },
  },
  textCenter: {
    textAlign: 'center',
  },
})

const RoomItemList = ({ items, fullWidth, hideBorders = false, text = 'This room includes', packageSkuMap }) => {
  const classes = useStyles({ fullWidth })

  if (!items || items.length === 0) {
    return null
  }

  const isProductIsComplete = item => {
    const numberOfPieces = Object.values(packageSkuMap).filter(value => value === item.sku).length
    return numberOfPieces === item.subItems.length
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid container item direction="column" xs={12} md={fullWidth ? 12 : 8} spacing={2}>
        <Grid item pt={5} className={classes.text}>
          <i>{text}:</i>
        </Grid>
        <Grid
          container
          item
          className={hideBorders ? null : classes.borders}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {items.map(item =>
            typeof item === 'string' ? (
              `${item} `
            ) : (
              <Grid item key={item.sku} xs={6} md>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                  {item?.subItems && !isProductIsComplete(item) ? (
                    <Grid item className={classes.textCenter}>
                      <span>{[...new Set(item.subItems)].reduce((acc, next) => `${acc}, ${next}`)}</span>
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <picture>
                          <source srcSet={item?.image ? `${item.image}&h=50` : null} />
                          <img
                            alt={item?.title ?? ''}
                            src={item?.image ? `${item.image}&h=50` : null}
                            role="presentation"
                            aria-hidden="true"
                            style={{ maxWidth: '100px' }}
                          />
                        </picture>
                      </Grid>
                      <Grid item className={classes.textCenter}>
                        <span>{`${titleCase((item?.generic_name || item?.title) ?? '')}${
                          item.quantity > 1 ? ` (${item.quantity})` : ''
                        }`}</span>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

RoomItemList.propTypes = {
  fullWidth: bool,
  hideBorders: bool,
  text: string,
  packageSkuMap: any,
  items: array,
}

RoomItemList.defaultProps = {}

export default RoomItemList
