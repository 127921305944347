import React, { useEffect } from 'react'
import { string, object, bool } from 'prop-types'
import { Box, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, styled } from '@mui/material'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { useModalsContext } from '@context/modalContext'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { useCreateAccountForm } from './hooks'
import { AuthErrorCodes } from '../../../lib/auth/firebase'
import { RequiredFieldsMessage, PasswordRequirements, FormGrid, InputGrid, SubmitButton } from '../Common'

const NoPaddingInputGrid = styled(InputGrid)`
  padding-left: 0 !important;
  padding-right: 14px !important;
`

const NoPaddingGrid = styled(Grid)`
  padding-left: 0 !important;
  padding-right: 14px !important;
`

const createInputInteractionTracking = (dataType = '', action = '') => () => {
  addToDataLayer_nextgen('account_modal_click', {
    account_modal_name: dataType ? `Create Account - ${dataType}` : 'Create Account',
    account_modal_action: action,
  })
}

const StyledHiddenContainer = styled(Grid)({
  display: 'none',
})

const SuccessMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '140px',
  svg: {
    fill: theme.palette.success.main,
  },
  p: {
    textAlign: 'center',
  },
}))

const hiddenFields = [
  { fieldName: 'firstName', autoComplete: 'given-name', label: 'First Name' },
  { fieldName: 'lastName', autoComplete: 'family-name', label: 'Last Name' },
  { fieldName: 'zipcode', autoComplete: 'postal-code', label: 'Delivery Zip Code' },
  { fieldName: 'email', autoComplete: 'email', label: 'Email' },
]

const CreateNewAccountShort = ({ className, hidePasswordTips, defaultFormValues = {} }) => {
  const { actions } = useModalsContext()

  const callbackSuccess = () => {
    if (typeof window !== 'undefined') window.open('/account/profile', '_blank')
  }

  const callbackError = error => {
    if (error?.code === AuthErrorCodes.EMAIL_EXISTS || error?.code === AuthErrorCodes.INTERNAL_ERROR) {
      actions.genericModal({
        overrideState: {
          theme: 'light',
          componentData: {
            title: 'Account Creation Unsuccessful!',
            message: 'An account currently exists with email ',
            email: defaultFormValues?.email,
          },
          styles: {
            '.MuiPaper-root': {
              maxWidth: '360px',
            },
          },
        },
        data: { PredefinedComponentModal: 'forget_password' },
      })
    }
  }

  const {
    formik,
    showPassword,
    showConfirmPassword,
    onVisibilityToggle,
    onVisibilityToggleConfirmPassword,
    loading,
    handleMouseDownPassword,
    handleMouseDownConfirmPassword,
    successOnCreation,
    errorMessage,
  } = useCreateAccountForm({ callbackSuccess, callbackError })

  const { errors, touched = {}, values = {} } = formik
  const { password, confirmPassword } = values
  const hasErrors = Object.keys(errors ?? {}).length > 0 || !confirmPassword

  useEffect(() => {
    if (defaultFormValues?.email) {
      formik?.setValues(defaultFormValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFormValues])

  useEffect(() => {
    if (password?.length > 0 && !touched?.password) {
      formik?.setTouched?.({ password: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password])

  if (successOnCreation) {
    return (
      <Box className={className}>
        <SuccessMessageContainer>
          <CheckCircleOutlined />
          <Typography>Your account has been successfully created!</Typography>
        </SuccessMessageContainer>
      </Box>
    )
  }

  return (
    <Box className={className}>
      <RequiredFieldsMessage requiredText="Required" />
      <FormGrid spacing={2} component="form" container onSubmit={formik?.handleSubmit}>
        {hiddenFields.map(({ fieldName, autoComplete, label }) => (
          <StyledHiddenContainer item key={`hidden_field_${fieldName}`}>
            <TextField
              hidden
              id={fieldName}
              autoComplete={autoComplete}
              name={fieldName}
              label={label}
              required
              value={values[fieldName]}
              onFocus={createInputInteractionTracking('Personal', label)}
              onChange={formik?.handleChange}
              error={touched[fieldName] && Boolean(errors[fieldName])}
              helperText={touched[fieldName] && errors[fieldName]}
            />
          </StyledHiddenContainer>
        ))}

        <NoPaddingGrid item xs={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="password"
            autoComplete="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            value={password}
            onChange={formik?.handleChange}
            onFocus={createInputInteractionTracking('Password', 'Password')}
            error={touched.password && Boolean(errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggle('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="configPassword"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            required
            value={confirmPassword}
            onChange={formik?.handleChange}
            onFocus={createInputInteractionTracking('Confirm Password', 'Confirm Password')}
            error={!!confirmPassword && Boolean(errors.confirmPassword)}
            helperText={confirmPassword && errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggleConfirmPassword('password')}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </NoPaddingGrid>
        {!hidePasswordTips && (
          <NoPaddingGrid item xs={12} sm={12} md={12} style={{ paddingTop: 0, fontSize: '14px' }}>
            <PasswordRequirements errors={errors} isTouched={touched.password} />
          </NoPaddingGrid>
        )}
        <NoPaddingInputGrid item xs={12} sm={12} md={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SubmitButton
              disabled={hasErrors}
              fullWidth
              onFocus={createInputInteractionTracking('Button', 'Submit')}
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              Create Account
            </SubmitButton>
          )}
        </NoPaddingInputGrid>
        {errorMessage && (
          <NoPaddingGrid item xs={12} sm={12} md={12}>
            <Typography color="error" style={{ fontSize: '14px' }}>
              {errorMessage}
            </Typography>
          </NoPaddingGrid>
        )}
      </FormGrid>
    </Box>
  )
}

CreateNewAccountShort.propTypes = {
  className: string,
  defaultFormValues: object,
  hidePasswordTips: bool,
}

export default CreateNewAccountShort
